<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4" style="max-width: 768px">
            <vx-card>
                <div class="vx-row no-gutter justify-center items-center">
                    <!-- 左logo圖 -->
                    <div class="vx-col hidden lg:block lg:w-1/2">
                        <img :src="logo" alt="Curdr-login-04.png" class="cd-login-logo" />
                    </div>
                    <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg sm:p-4 p-2 login-tabs-container" style="margin-bottom: 0.5em">
                        <div class="vx-card__title">
                            <!-- 手機板logo圖 -->
                            <vs-row vs-align="center" v-if="windowWidth <= 768">
                                <vs-col class="layout--full-page">
                                    <!-- <img :src="logo" alt="Curdr-login-04.png" class="cd-login-logo"> -->
                                    <!-- <img src="/assets/image/logo/iso27001.png" alt="Curdr-login-04.png" class="cd-login-logo"> -->
                                </vs-col>
                            </vs-row>
                            <vs-row>
                                <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between" vs-align="center">
                                    <!-- 標題 -->
                                    <h3>{{ $t('login.login') }}</h3>
                                    <!-- 選擇語系 -->
                                    <vs-dropdown vs-custom-content vs-trigger-click>
                                        <vs-col class="cd-i18n-dropdown cd-text-muted" vs-type="flex" vs-align="center">
                                            <vs-icon icon="language"></vs-icon>
                                            <p class="ml-1">{{ getCurrentLocaleData.lang }}</p>
                                        </vs-col>
                                        <vs-dropdown-menu class="w-32">
                                            <vs-dropdown-item @click="updateLocale('tw')">繁體中文</vs-dropdown-item>
                                            <vs-dropdown-item @click="updateLocale('cn')">简体中文</vs-dropdown-item>
                                            <vs-dropdown-item @click="updateLocale('en')">English</vs-dropdown-item>
                                        </vs-dropdown-menu>
                                    </vs-dropdown>
                                </vs-col>
                            </vs-row>
                        </div>
                        <vs-row>
                            <!-- 身分證或手機 tabs -->
                            <vs-col>
                                <!-- <vs-tabs alignment="fixed" v-model="identify_radio">
                  身分證
                  <vs-tab :label="$t('login.id')" @click="switchtab(0)">
                  </vs-tab>
                  手機
                  <vs-tab :label="$t('login.phone')" @click="switchtab(1)">
                  </vs-tab>
                </vs-tabs> -->
                                <div class="flex mb-2">
                                    <div class="p-2 w-1/2 text-center cursor-pointer" :style="identify_radio == 0 ? 'border-bottom: 2px solid #003f8e; color: #003f8e;' : ''" @click="switchtab(0)">
                                        <span>{{ $t('login.id') }}</span>
                                    </div>
                                    <div class="p-2 w-1/2 text-center cursor-pointer" :style="identify_radio == 1 ? 'border-bottom: 2px solid #003f8e; color: #003f8e;' : ''" @click="switchtab(1)">
                                        <span>{{ $t('login.phone') }}</span>
                                    </div>
                                </div>
                            </vs-col>
                            <!-- 手機國碼 -->
                            <vs-col class="cd-form-group" vs-type="flex" v-if="loginForm.identify_radio == 1">
                                <v-select class="w-full cd-select" autocomplete :clearable="false" v-model="countryCode" :options="userinfo_country_code" label="label" :reduce="(userinfo_country_code) => `${userinfo_country_code.phoneCode}`" />
                                <feather-icon class="cd-icon-muted ml-2 mt-3" icon="" />
                            </vs-col>
                            <!-- 帳號 input -->
                            <vs-col class="cd-form-group vs-con-input" vs-type="flex">
                                <vs-input class="w-full" autofocus icon-no-border :placeholder="$t('login.account')" icon-pack="feather" icon="icon-user" v-model="account" />
                                <feather-icon class="cd-icon-muted ml-2 mt-3" icon="" />
                            </vs-col>
                            <!-- 密碼 input -->
                            <vs-col class="cd-form-group" vs-type="flex">
                                <vs-input class="w-full" icon-no-border :type="passwordType" :placeholder="$t('login.password')" icon-pack="feather" icon="icon-lock" :danger="errorBool" :danger-text="errorMsg" v-model="pwd" @keyup.enter.native="handleLogin" />
                                <feather-icon class="cd-icon-muted ml-2 mt-3" :icon="passwordIcon" @click="showPassword" />
                            </vs-col>
                            <vs-col class="cd-form-group" vs-type="flex" vs-justify="space-between">
                                <!-- 記住帳號 -->
                                <vs-checkbox v-model="checkbox_remember_me" @keyup.enter="handleLogin">{{ $t('login.rememberAccount') }}</vs-checkbox>
                                <!-- 忘記密碼 -->
                                <router-link to="/pages/forgetpassword">{{ $t('login.forgetPassword') }}</router-link>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="space-between">
                                <!-- 註冊 -->
                                <vs-button class="px-6 sm:px-8" type="border" @click="registerUser">{{ $t('login.register') }}</vs-button>
                                <!-- 登入 -->
                                <vs-button class="px-6 sm:px-8" @click.native.prevent="handleLogin" @keyup.enter="handleLogin">{{ $t('login.login') }}</vs-button>
                            </vs-col>
                        </vs-row>
                    </div>
                    <!-- <img src="@/assets/images/logo/iso27001.png" style="width:130px;height:52px"> -->
                </div>
            </vx-card>
            <!-- 登入選擇合作夥伴類別視窗-->
            <vs-popup :active.sync="choosePartnerGroupPopup" title="">
                <div class="px-8 mb-base" v-if="partnerlist">
                    <div class="py-2">
                        <feather-icon class="mr-2 invisible" icon="MapPinIcon" svgClasses="w-4 h-4"></feather-icon>
                        <span class="font-semibold">{{ $t('login.choosePartner') }}</span>
                    </div>
                    <div class="flex items-center py-2 cursor-pointer w-full text-primary hover:font-semibold" v-for="(item, index) in partnerlist" :key="index" @click="updatePartner(item.id)">
                        <feather-icon class="mr-2 invisible" icon="MapPinIcon" svgClasses="w-4 h-4"></feather-icon>
                        <span>{{ item.company }}</span>
                    </div>
                </div>
            </vs-popup>
        </div>
    </div>
</template>

<script>
import { countryCodes } from '@/api/countryCodes.js'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { loginLog, getUIAPartnrtList, getTokenFromCart, getAllowShop } from '@/api/user'
import vSelect from 'vue-select'
import _ from 'lodash'
import FeatherIcon from '../../components/FeatherIcon.vue'
import selectPartner from '@/components/selectPartner.vue'

export default {
    components: {
        FeatherIcon,
        vSelect,
        selectPartner,
    },
    data() {
        return {
            tokenId: localStorage.getItem('di_tokenId'),
            ss: null,
            identify_radio: 0,
            countryCode: '+886',
            pwd: '',
            account: '',
            device_token: '',
            // loading: false,
            errorMsg: '',
            errorBool: false,
            reload: false,
            checkbox_remember_me: false,
            passwordType: 'password',
            passwordIcon: 'EyeOffIcon',
            choosePartnerGroupPopup: false, //關閉切換合作夥伴視窗
            partnerlist: [], //取合作夥伴清單
            vertify: false, //確認使用者是否通過驗證
            choosePartnerFlag: false,
            staffType: 0,
        }
    },
    watch: {
        choosePartnerGroupPopup(n) {
            if (!n && !this.choosePartnerFlag) {
                this.logout()
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        if (localStorage.getItem('di_tokenId')) next({ path: '/' })
        else next()
    },
    async mounted() {
        // 判斷使用者是否有login_data資料
        if (localStorage.getItem('login_data') != null) {
            this.get_all_login_data()
        }
        //註冊用partner
        if (this.$route.hash) {
            this.partner = this.$route.hash.slice(11)
            console.log('registerpartner', this.partner)
            localStorage.setItem('register_partner', this.partner)
        }
        // 儲存logo
        localStorage.setItem('login_logo', this.logo)
    },
    computed: {
        loginForm() {
            let rs = {
                identify_radio: this.identify_radio,
                countryCode: this.countryCode,
                id: this.account,
                pwd: this.pwd,
                is_doctor: 1,
                voipToken: localStorage.getItem('doc_access_token'),
                apnsToken: '',
                phoneType: localStorage.getItem('doc_access_phoneType'),
                accessToken: localStorage.getItem('doc_access_token'),
                appLang: localStorage.getItem('language'),
            }
            rs.apnsToken = localStorage.getItem('doc_access_apns') ? localStorage.getItem('doc_access_apns') : localStorage.getItem('doc_access_token')
            return rs
        },
        getCurrentLocaleData() {
            const locale = this.$i18n.locale
            if (locale == 'tw') return { flag: 'tw', lang: '繁體中文' }
            else if (locale == 'cn') return { flag: 'cn', lang: '简体中文' }
            else if (locale == 'en') return { flag: 'us', lang: 'English' }
            // else if (locale == "fr") return { flag: "fr", lang: 'French' }
            // else if (locale == "de") return { flag: "de", lang: 'German' }
            return ''
        },
        device() {
            return this.$store.getters.device
        },
        isConnected() {
            return this.$store.getters.isConnected
        },
        socketID() {
            return this.$store.getters.socketID
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        isDev() {
            return this.$store.getters.isDev
        },
        userinfo_country_code() {
            countryCodes.forEach((element) => {
                if (this.$i18n.locale == 'tw') {
                    element.label = element.countryName + ' (' + element.phoneCode + ')'
                } else if (this.$i18n.locale == 'cn') {
                    element.label = element.countryNameCN + ' (' + element.phoneCode + ')'
                } else if (this.$i18n.locale == 'en') {
                    element.label = element.countryCode + ' (' + element.phoneCode + ')'
                }
            })
            return countryCodes
        },
        logo() {
            if (window.location.hash == '#Alliance') {
                return require('@/assets/images/logo/alliance-login.png')
            } else if (window.location.hash == '#ChengHsin') {
                return require('@/assets/images/logo/chenghsin.png')
            } else if (window.location.hash == '#CamianSaint') {
                return require('@/assets/images/logo/camiansaintlogo.png')
            } else if (window.location.hash == '#CLCcare') {
                return require('@/assets/images/logo/clc_care-login.png')
            } else if (window.location.hash == '#PingChrist') {
                return require('@/assets/images/logo/pingchrist.png')
            } else if (window.location.hash == '#huayu') {
                return require('@/assets/images/logo/huayu.png')
            } else {
                return localStorage.getItem('login_logo') ? localStorage.getItem('login_logo') : require('@/assets/images/logo/Curdr-login-05.png')
            }
        },
        totalCountExceptMember() {
            return this.$store.getters.totalCountExceptMember
        },
    },
    methods: {
        // 讀取所有localstorage登入資料帶入
        get_all_login_data() {
            var get_all_login_data = JSON.parse(localStorage.getItem('login_data'))
            // this.loginForm.identify_radio = get_all_login_data['identify_radio']
            this.identify_radio = get_all_login_data['identify_radio']
            this.account = get_all_login_data['id']
            this.checkbox_remember_me = get_all_login_data['checkbox_remember_me']
            this.countryCode = get_all_login_data['countryCode']
            // this.loginForm.countryCode = get_all_login_data['countryCode']
        },
        // 更新語系
        updateLocale(locale) {
            this.$i18n.locale = locale
            if (locale == 'en') {
                localStorage.setItem('language', 'en')
            } else if (locale == 'tw') {
                localStorage.setItem('language', 'zh_TW')
            } else if (locale == 'cn') {
                localStorage.setItem('language', 'zh_CN')
            }
        },
        // 切換合作夥伴
        updatePartner(partnerid) {
            this.choosePartnerFlag = true
            localStorage.setItem('partnerlist', JSON.stringify(this.partnerlist))
            localStorage.setItem('partnerid', partnerid)
            // getTokenFromCart
            this.getAllowShop()
            this.choosePartnerGroupPopup = false
            this.$store.dispatch('getConfig', partnerid).then((res) => {
                localStorage.setItem('his_config', JSON.stringify(res.data.data))
                localStorage.setItem('partner_info', JSON.stringify(res.data.pi_data))
                localStorage.setItem('is_p2p', res.data.pi_data.is_p2p)
                let data = res.data.data
                this.vertify ? this.$router.push({ path: data.loginRouter.vertify }) : this.$router.push({ path: data.loginRouter.unvertify })
            })
        },
        // 跳轉至註冊頁面
        registerUser() {
            this.$router.push('/pages/register').catch(() => {})
        },
        // 登入
        handleLogin() {
            const _self = this
            // _self.loading = true
            _self.$vs.loading()
            _self.loginForm.appLang = localStorage.getItem('language')
            // 儲存所有登入資訊
            if (_self.checkbox_remember_me == true) {
                var keep_all_login_data = { identify_radio: _self.loginForm.identify_radio, id: _self.account, checkbox_remember_me: _self.checkbox_remember_me, countryCode: _self.loginForm.countryCode }
                localStorage.setItem('login_data', JSON.stringify(keep_all_login_data))
            }
            if (_self.checkbox_remember_me == false) {
                localStorage.removeItem('login_data')
            }
            if (_self.loginForm.voipToken == '' || _self.loginForm.apnsToken == '') {
                _self.errorMsg = _self.$t('login.failedAlert1')
                _self.failLoginNotification(_self.errorMsg)
                _self.$vs.loading.close()
                return false
            }
            if (_self.loginForm.identify_radio == '1') {
                const pattern = /^9\d{8}$/
                _self.loginForm.id = _self.loginForm.countryCode.split('+')[1]
                if (pattern.test(_self.account)) _self.loginForm.id += '0'
                _self.loginForm.id += _self.account
            }
            _self.$store
                .dispatch('login', _self.loginForm)
                .then((res) => {
                    // 登入失敗 請輸入正確資料 (有空欄位)
                    if (res.data.status == 'nodata') {
                        _self.errorMsg = _self.$t('login.failedAlert2')
                        _self.errorBool = true
                        _self.failLoginNotification(_self.errorMsg)
                        _self.$vs.loading.close()
                    }
                    // 登入失敗
                    else if (res.data.status == 'ERROR') {
                        // 密碼錯誤
                        if (res.data.msg == 'error_pwd') {
                            _self.errorMsg = _self.$t('login.failedAlert3')
                        }
                        // 找不到使用者
                        else if (res.data.msg == 'notFound') {
                            _self.errorMsg = _self.$t('login.failedAlert4')
                        }
                        // 此帳號已關閉
                        else if (res.data.msg == 'doctor not auth') {
                            _self.errorMsg = _self.$t('login.failedAlert5')
                        }
                        // 密碼輸入錯誤超過4次
                        else if (res.data.msg == 'login_many_time') {
                            _self.errorMsg = _self.$t('login.failedAlert6')
                        }
                        _self.errorBool = true
                        _self.failLoginNotification(_self.errorMsg)
                        _self.$vs.loading.close()
                    }
                    // 成功登入 但未完成驗證
                    else if (res.data.status == 'unfinsh_verify' && res.data.token != '') {
                        _self.vertify = false
                        _self.$store.commit('SOCKET_CONNECT')
                        if (_self.isConnected) {
                            loginLog()
                            localStorage.removeItem('login_logo')
                            _self.getUIAPartner()
                        }
                    }
                    // 成功登入
                    else if (res.data.status == 'OK' && _self.tokenId != '') {
                        _self.vertify = true
                        const userId = Math.floor(Math.random().toFixed(4) * 10000)
                        const socketId = _self.socketID
                        if (_self.tokenId != '') {
                            _self.ss = _self.$socket.client.emit('user_login', { userId, socketId, token: localStorage.getItem('di_tokenId') })
                            console.log('_self.ss', _self.ss)
                        }
                        localStorage.setItem('refresh_token', res.data.refresh_token)
                        _self.$store.dispatch('updateDoctorStatus', 1000)
                        loginLog()
                        localStorage.removeItem('login_logo')
                        _self.getUIAPartner()
                    }
                })
                .catch((e) => {
                    // _self.loading = false
                    _self.$vs.loading.close()
                    console.log('login error ', e)
                })
            // .then(() => {
            //   _self.loading = false
            // })
        },
        // 登出
        logout() {
            var _self = this
            _self.$store.dispatch('updateDoctorStatus', 3000)
            if (_self.device == 1) {
                updateBadge(_self.totalCountExceptMember)
            }
            _self.$store
                .dispatch('logout')
                .then(() => {
                    _self.$socket.client.emit('login_out')
                })
                .catch((error) => {
                    console.log(error)
                })
                .then(() => {
                    _self.$socket.client.close()
                })
        },
        // 切換 身分證/手機 tab
        switchtab(index) {
            this.identify_radio = index
        },
        // 顯示密碼
        showPassword() {
            this.passwordType == 'text' ? (this.passwordType = 'password') : (this.passwordType = 'text')
            this.passwordType == 'text' ? (this.passwordIcon = 'EyeIcon') : (this.passwordIcon = 'EyeOffIcon')
        },
        // 取該帳號所有的合作夥伴ID (is_uia參數使用 待確認)
        async getUIAPartner() {
            await getUIAPartnrtList({ is_uia: 0 }).then((res) => {
                if (res.data.status != 'success') return 'get partner api error!'
                this.partnerlist = res.data.results
            })

            if (!this.partnerlist) {
                this.$confirm(this.$t('login.confirmLogin'), {
                    showCancelButton: false,
                    type: 'warning',
                })
                console.log('ERROR no partnerid!!!')
                return false
            }

            await this.getPartnerList()
            await this.partnerCondition()
            this.$vs.loading.close()
        },
        // 登入失敗通知
        failLoginNotification(errorMsg) {
            this.$vs.notify({
                title: this.$t('login.loginFailed'),
                text: errorMsg,
                color: 'danger',
                position: 'top-center',
            })
        },
        // 取 Partner List
        getPartnerList() {
            localStorage.setItem('partnerlist', JSON.stringify(this.partnerlist))
        },
        // 合作夥伴登入狀況
        partnerCondition() {
            let list = JSON.parse(localStorage.getItem('partnerlist'))
            if (!list) {
                this.$confirm(this.$t('login.confirmLogin'), {
                    showCancelButton: false,
                    type: 'warning',
                })
                console.log('ERROR no partnerid!!!')
                return false
            }

            if (list.length == 1) {
                localStorage.setItem('partnerid', list[0].id)
                this.$store.dispatch('getConfig', list[0].id).then((res) => {
                    localStorage.setItem('his_config', JSON.stringify(res.data.data))
                    localStorage.setItem('partner_info', JSON.stringify(res.data.pi_data))
                    localStorage.setItem('is_p2p', res.data.pi_data.is_p2p)
                    let data = res.data.data
                    this.vertify ? this.$router.push({ path: data.loginRouter.vertify }) : this.$router.push({ path: data.loginRouter.unvertify })
                })
            } else {
                setTimeout(() => {
                    this.choosePartnerGroupPopup = true
                }, 500)
            }
        },
        //檢查partner商城開關
        getAllowShop() {
            let payload = {
                partner_id: localStorage.getItem('partnerid'),
            }
            getAllowShop(payload).then((res) => {
                if (res.data.status == 'OK') {
                    if (res.data.token) {
                        localStorage.setItem('cart_token', res.data.token)
                    }
                }
            })
        },
    },
}
</script>
