<template>
    <vs-popup :active.sync="active" @close="close" title="">
        <div class="sm:px-8 px-2 mb-base whitespace-no-wrap" v-if="partnerlist">
            <div class="py-2">
                <feather-icon class="mr-2 invisible" icon="MapPinIcon" svgClasses="w-4 h-4"></feather-icon>
                <span class="font-semibold">{{ $t('components.selectPartner.choosePartner') }}</span>
            </div>
            <div class="flex justify-between items-center py-2 w-full" :class="item.id == partnerid ? 'font-semibold' : 'cursor-pointer text-primary hover:font-semibold'" v-for="(item, index) in partnerlist" :key="index" @click="selectPartner(item.id)">
                <div class="flex items-center">
                    <feather-icon class="mr-2" :class="item.id == partnerid ? '' : 'invisible'" icon="MapPinIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ item.company }}</span>
                </div>
                <div class="flex text-black font-normal">
                    <span v-if="item.todayApptTotal > 0">{{ $t('components.selectPartner.today') }}</span>
                    <span class="cd-badge-text" v-if="item.todayApptTotal > 0">{{ item.todayApptTotal }}</span>
                    <span class="ml-4" v-if="item.allApptTotal > 0">{{ $t('components.selectPartner.all') }}</span>
                    <span class="text-primary" v-if="item.allApptTotal > 0">{{ item.allApptTotal }}</span>
                </div>
            </div>
        </div>
    </vs-popup>
</template>
<script>
import { fetchApptUndoneQuantity, getTokenFromCart, getAllowShop } from '@/api/user'
import FeatherIcon from './FeatherIcon.vue'
export default {
    components: { FeatherIcon },
    data() {
        return {
            active: false,
            partnerlist: null,
        }
    },
    props: {
        openPopup: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    watch: {
        openPopup(n) {
            this.active = n
        },
        apptUndoneTotal() {
            this.getPartnerList()
        },
    },
    computed: {
        partnerid() {
            return this.$store.getters.partner
        },
        apptUndoneTotal() {
            return this.$store.state.apptUndoneTotal
        },
    },
    mounted() {
        this.getPartnerList()
    },
    methods: {
        // 切換合作夥伴
        async selectPartner(id) {
            if (id == this.partnerid) return
            await this.getAllowShop(id)
            localStorage.setItem('partnerid', id)
            await this.$store.dispatch('getConfig', id).then((res) => {
                localStorage.setItem('his_config', JSON.stringify(res.data.data))
                localStorage.setItem('partner_info', JSON.stringify(res.data.pi_data))
                localStorage.setItem('is_p2p', res.data.pi_data.is_p2p)
            })
            console.log('p2p註冊', 'curdr_' + this.partnerid + '_' + this.mid + '_1')
            p2p_register('curdr_' + this.partnerid + '_' + this.mid + '_1')
            this.$emit('closePartnerPopup', false)
            location.reload()
        },
        // 關閉視窗
        close() {
            this.$emit('closePartnerPopup', false)
        },
        // 取 Partner List
        getPartnerList() {
            const today = new Date()
            var list = null

            const todayPayload = {
                start_date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
                end_date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
            }
            fetchApptUndoneQuantity(todayPayload)
                .then((res) => {
                    list = JSON.parse(localStorage.getItem('partnerlist')).map(function (item) {
                        var match = res.data.data.find(function (el) {
                            return el.partner_id.toString() === item.id
                        })

                        return {
                            id: item.id,
                            company: item.company,
                            total: item.total,
                            todayApptTotal: match ? match.total : '0',
                        }
                    })
                })
                .then(() => {
                    const futurePayload = {
                        start_date: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
                        end_date: today.getFullYear() + 999 + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
                    }
                    fetchApptUndoneQuantity(futurePayload).then((res) => {
                        list = list.map(function (item) {
                            var match = res.data.data.find(function (el) {
                                return el.partner_id.toString() === item.id
                            })

                            return {
                                id: item.id,
                                company: item.company,
                                total: item.total,
                                todayApptTotal: item.todayApptTotal,
                                allApptTotal: match ? match.total : '0',
                            }
                        })
                        this.partnerlist = list
                    })
                })
        },
        // 商城判斷切換合作夥伴是否註冊商城token
        getAllowShop(id) {
            let payload = {
                partner_id: id,
            }
            getAllowShop(payload).then((res) => {
                if (res.data.token) {
                    localStorage.setItem('cart_token', res.data.token)
                }
            })
        },
    },
}
</script>
